<template lang="pug">
    div
        loader(v-if="$root.loader")
        template(v-else)
            header-component
                v-btn.no-margin(absolute dark fab top right color='primary' @click.stop="drawerRight = !drawerRight")
                    v-icon search
            v-navigation-drawer.filters.grey.darken-3(fixed v-model="drawerRight" right app width="246")
                v-form
                    v-toolbar.grey.darken-1(flat)
                        v-text-field(v-model="filter.query" :placeholder="$t('Buscar...')" dark)
                        v-icon(dark) search
                    .filters-content
                        .white--text.mb-2 {{$t('Filtrar por')}}:
                        v-select.mb-3(
                            v-model="filter.network"
                            :items='networkNames'
                            item-text="name"
                            item-value="uuid"
                            :label='$t("Red")'
                            append-icon="keyboard_arrow_down" 
                            autocomplete
                            :filter="vFilter"
                            dark
                            color="white"
                            deletable-chips
                        )
                        v-select.mb-3(
                            v-model="filter.pending"
                            :items='filterPending'
                            item-text="text"
                            item-value="key"
                            :label='$t("Pendiente por aprobar")'
                            append-icon="keyboard_arrow_down" 
                            autocomplete
                            :filter="vFilter"
                            dark
                            color="white"
                        )
                        .btns.btns-right
                            v-btn(@click="cleanFilter" color="primary") {{$t('Limpiar')}}
            v-content
                v-snackbar(
                :timeout="3000",
                :top="true"
                :right="true"
                :color="saveColor"
                v-model="save")
                    | {{ saveText }}
                    v-btn(flat, dark, @click.native="save = false") {{$t('cerrar')}}
                v-container.grid-list-xl(fluid)
                    h2 {{$t('Usuarios personalización de redes')}}
                    template(v-if="preload")
                        h3 {{$t('Cargando')}}...
                    template(v-else)
                        v-layout(row wrap v-if="users.length")
                            v-flex(xs12 sm6 md4 lg3 xl2 v-for="user in users" :key="user.uuid")
                                .sheet.grey.lighten-3
                                    .sheet-options
                                        v-menu(left)
                                            v-icon(color='primary' dark slot='activator') more_vert
                                            v-list.sheet-menu
                                                v-list-tile.white--text(@click="$refs.custom.open(user)")
                                                    v-list-tile-title {{$t('Personalizar red')}}
                                                v-list-tile.white--text(v-if="user.pending" @click="$refs.pending.open(user)")
                                                    v-list-tile-title {{$t('Cambios pendientes')}}
                                    .sheet-title
                                        | {{ user.names }}
                                    .sheet-content
                                        p
                                            span.d-block {{$t('Red')}}: {{ user.network }}
                                        v-alert(v-if="user.pending" :value="true" outline color="info" icon="info")
                                            small {{ $t('Cambios pendientes por aprobar') }}
                            v-flex(xs12)
                                infinite-loading(:infinite="infinite" ref="infinite")
                        .not-found(v-else)
                            h3 {{$t('No se encontraron usuarios')}}
                        custom-network-config(ref="custom" @success="fetch")
                        custom-network-pending(ref="pending" @success="fetch")
</template>

<script>

    import auth from 'mixins/auth'
    import config from 'src/config'
    import merge from 'lodash/merge'
    import CustomNetworkConfig from 'components/network/CustomNetworkConfig'
    import CustomNetworkPending from 'components/network/CustomNetworkPending'

    export default {
        mixins: [auth],
        metaInfo() {
            return {
                title: this.$t('Usuarios personalización de redes')
            }
        },
        data(){
            return {
                save     : false,
                preload  : false,
                saveColor: 'error',
                saveText : '',
                config: {
                    name       : '',
                    text       : '',
                    hometext   : ' ',
                    logintext  : ' ',
                    time       : '',

                    // Imagen para compartir
                    shareImg  : '',
                    share     : '',

                    //Imagen de registro
                    registerImg: '',
                    register   : ''
                },
                loadingBtn: false,

                drawerRight: false,
                filterPending: [
                    {
                        key: 1,
                        text: 'SI'
                    },
                    {
                        key: 0,
                        text: 'NO'
                    }
                ],
                filter: {},
                filterSchema: {
                    query    : '',
                    network  : '',
                    pending  : ''
                },
                networkNames : [],
                limit : this.$root.limit,
                offset: 0,
                users: []
            }
        },
        computed: {
            profile(){
                return this.$root.profile
            },
            imgurl(){
                return config.imgurl
            },
            uploadurl(){
                return config.uploadurl
            }
        },
        watch: {
            filter: {
                handler(){
                    this.fetch()
                },
                deep: true
            },
            profile: {
                handler(){
                    this.fetchNetworkNames()
                },
                deep: true
            }
        },
        mounted(){
            this.fetch()
        },
        methods: {
            fetch(infinite = false){

                if(infinite){
                    this.offset += this.limit
                }else{
                    this.preload = true
                    this.leader = []
                    this.offset = 0
                    this.$refs.infinite && this.$refs.infinite.reset()
                }

                let filter = {
                    limit : this.limit,
                    offset: this.offset,
                    filter: JSON.stringify(this.filter)
                }

                this.$api(this, (xhr) => {
                    xhr.get('/custom-network/users/list', { params: filter }).then((r) => {

                        if(!infinite) this.preload = false
                        let data = r.data

                        if(infinite){
                            if(data.response){
                                this.users = this.users.concat(data.data);
                                this.$refs.infinite && this.$refs.infinite.loaded()
                            }else{
                                this.$refs.infinite && this.$refs.infinite.complete()
                            }
                        }else{
                            this.users = (data.response ? data.data : [])
                        }
                    }).catch(() => {
                        this.preload = false
                    })
                })
            },
            infinite(){
                setTimeout(() => this.fetch(true) , 500)
            },
            saveConfig(){

                let post = this._.cloneDeep(this.config)

                if('shareImg' in post){
                    delete post.shareImg
                }

                if('registerImg' in post){
                    delete post.registerImg
                }

                if('time' in post){
                    delete post.time
                }

                let form = new FormData()
                for(let i in post){
                    form.append(i, post[i])
                }

                this.loadingBtn = true
                this.$api(this, (xhr) => {
                    xhr.post('/custom-network/config', form).then((r) => {

                        let data = r.data
                        this.loadingBtn = false

                        this.save = true
                        this.saveText = data.message

                        if(data.response){
                            this.saveColor = 'success'
                        }else{
                            this.saveColor = 'error'
                        }
                    }).catch(() => {
                        this.loadingBtn = false
                    })
                })
            },
            fileChange(e, model, save) {

                var files = e.target.files || e.dataTransfer.files
                if (!files.length) return
                this.createImage(files[0], model, save)
            },
            createImage(file, model, save) {

                let reader = new FileReader();
                reader.onload = (e) => this.config[model] = e.target.result
                reader.readAsDataURL(file)
                this.config[save] = file
            },
            removeImage(model, save) {
                this.config[model] = ''
                this.config[save] = ''
            },
            fetchNetworkNames(){

                let url = '/network?list=1'

                if(this.$root.profile.type == 2){
                    url = '/network/profile?list=1'
                }

                if(this.$root.profile.type == 1){
                    url = '/network/profile?email=1&list=1'
                }

                this.$api(this, (xhr) => {
                    xhr.get(url).then((r) => {

                        let data = r.data
                        if(data.response){
                            let networkNames = [[]].concat(data.data) 
                            this.networkNames = networkNames
                        }
                    }).catch(() => {})
                })
            },
        },
        components: {
            CustomNetworkConfig,
            CustomNetworkPending
        }
    }

</script>