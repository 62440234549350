<template lang="pug">
    div
        v-snackbar(
        :timeout="3000"
        :top="true"
        :right="true"
        :color="saveColor"
        v-model="saveMsj")
            | {{ saveText }}
            v-btn(flat dark @click.native="saveMsj = false") {{ $t('cerrar') }}
        v-dialog(lazy max-width="900px" v-model="custom")
            v-card
                v-card-title 
                    .btns.btns-right
                        v-btn(icon color="secondary" small @click.native="custom=false")
                            v-icon close
                    h3.full-width {{ $t('Personalizar red') }} - {{ current.network }}
                v-card-text
                    template(v-if="preload")
                        h3 {{$t('Cargando')}}...
                    template(v-else)
                        v-form
                            v-layout(row wrap)
                                v-flex(xs12)
                                    v-text-field(:label="$t('Nombre de la red')", v-model="config.name")
                                v-flex(xs12)
                                    h4.my-2 {{$t('Texto a compartir')}}
                                    v-text-field(multi-line v-model="config.text")
                                v-flex(xs12)
                                    h4.my-2 {{$t('Texto de registro/inicio de sesión')}}
                                    editor(:init="$root.editor" v-model="config.logintext")
                                v-flex(xs12)
                                    h4.my-2 {{$t('Texto de inicio')}}
                                    editor(:init="$root.editor" v-model="config.hometext")
                                v-flex(xs12 sm6)
                                    h3.mb-3 {{$t('Foto para inicio')}}
                                    v-btn.btn-file(color="success" outline block large) 
                                        v-icon(left) file_upload
                                        | {{$t('Subir foto')}}
                                        input(type='file' @change='fileChange($event, "shareImg", "share")')
                                    .img-file(v-if="config.shareImg.length")
                                        img(:src="config.shareImg")
                                        v-icon(@click='removeImage("shareImg", "share")') close
                                    .img-file(v-else)
                                        img(:src="config.share ? uploadurl + config.share + '?time=' + config.time : imgurl")
                                v-flex(xs12 sm6)
                                    h3.mb-3 {{$t('Foto para registro')}}
                                    v-btn.btn-file(color="success" outline block large) 
                                            v-icon(left) file_upload
                                            | {{$t('Subir foto')}}
                                            input(type='file', @change='fileChange($event, "registerImg", "register")')
                                    .img-file(v-if="config.registerImg.length")
                                        img(:src='config.registerImg')
                                        v-icon(@click='removeImage("registerImg", "register")') close
                                    .img-file(v-else)
                                        img(:src="config.register ? uploadurl + config.register + '?time=' + config.time : imgurl")
                v-card-actions
                    .btns.btns-right
                        v-btn(color="secondary" small @click="custom=false") {{$t('Aceptar')}}
                        v-btn(v-if="!preload" color="secondary" :loading="loadingBtn" :disabled="loadingBtn" small @click="saveConfig") {{$t('Guardar')}}
</template>

<script>

import config from 'src/config'
import merge from 'lodash/merge'

export default {
    data(){
        return {
            custom    : false,
            saveMsj   : false,
            saveColor : '',
            saveText  : '',
            preload   : true,
            current   : {},
            configSchema: {
                name       : '',
                text       : '',
                hometext   : ' ',
                logintext  : ' ',
                time       : '',

                // Imagen para compartir
                shareImg  : '',
                share     : '',

                //Imagen de registro
                registerImg: '',
                register   : ''
            },
            config: {},
            loadingBtn: false
        }
    },
    computed: {
        imgurl(){
            return config.imgurl
        },
        uploadurl(){
            return config.uploadurl
        }
    },
    methods: {
        fetch(){

            this.preload = true
            this.$api(this, (xhr) => {
                xhr.get('/custom-network/config-admin', {
                    params: {
                        user   : this.current.uuid,
                        network: this.current.networkid
                    }
                }).then((r) => {
                    let data = r.data
                    if(data.response){
                        
                        this.config = merge(this.config, data.data)
                        this.preload = false
                    }else{
                        this.preload = false
                    }
                }).catch(() => {
                    this.preload = false
                })
            })
        },
        async open(item){

            this.current = this._.cloneDeep(item)
            this.config  = this._.cloneDeep(this.configSchema)

            await this.$nextTick()

            this.custom  = true
            this.fetch()
        },
        saveConfig(){

            let post = this._.cloneDeep(this.config)

            if('shareImg' in post){
                delete post.shareImg
            }

            if('registerImg' in post){
                delete post.registerImg
            }

            if('time' in post){
                delete post.time
            }

            let form = new FormData()
            for(let i in post){
                form.append(i, post[i])
            }

            form.append('user', this.current.uuid),
            form.append('network', this.current.networkid)

            this.loadingBtn = true
            this.$api(this, (xhr) => {
                xhr.post('/custom-network/config-admin', form).then((r) => {

                    let data = r.data
                    this.loadingBtn = false

                    this.saveMsj  = true
                    this.saveText = data.message

                    if(data.response){
                        this.saveColor = 'success'
                        this.custom = false
                        this.$emit('success')
                    }else{
                        this.saveColor = 'error'
                    }
                }).catch(() => {
                    this.loadingBtn = false
                })
            })
        },
        fileChange(e, model, save) {

            var files = e.target.files || e.dataTransfer.files
            if (!files.length) return
            this.createImage(files[0], model, save)
        },
        createImage(file, model, save) {

            let reader = new FileReader();
            reader.onload = (e) => this.config[model] = e.target.result
            reader.readAsDataURL(file)
            this.config[save] = file
        },
        removeImage(model, save) {
            this.config[model] = ''
            this.config[save] = ''
        },
    }
}

</script>